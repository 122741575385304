<template>
  <v-container fluid>
    <v-card outlined class="pa-5 mt-3">
      <v-row>
        <v-col cols="auto">
          <v-radio-group dense hide-details v-model="particularType" row>
            <v-radio
              v-for="type in particularTypes"
              :key="type.value"
              :label="type.text"
              :value="type.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <v-autocomplete
            outlined
            dense
            hide-details
            class="mt-2"
            v-model="headerGuid"
            :label="$t('tests.testName')"
            :items="testHeaders"
            item-text="text"
            item-value="value"
          ></v-autocomplete
        ></v-col>
      </v-row>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          :label="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.isInRole(85)"
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="testBodies"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <v-dialog v-model="dialogParameters" persistent max-width="750px">
      <v-form ref="formParameter" v-model="validParameter" lazy-validation>
        <v-card>
          <v-toolbar color="primary darken-1" dark>
            <v-card-title>
              {{ $t("tests.linkParameters") }}
            </v-card-title>
          </v-toolbar>
          <v-card-title> </v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="addParameter">
                  {{ $t("add") }}
                  <v-icon> mdi-plus </v-icon>
                </v-btn></v-col
              >
              <v-col cols="auto">
                <v-chip outlined class="mx-1" color="primary" label>
                  {{
                    $t("tests.parameterName") + " : " + editedItem.parameterName
                  }}
                </v-chip>

                <v-chip outlined class="mx-1" color="primary" label>
                  {{
                    $t("tests.parameterCode") + " : " + editedItem.parameterCode
                  }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider />

          <v-card-text>
            <v-list>
              <v-list-item
                v-for="(item, i) in editedItem.testMachineInterfaces"
                :key="i"
              >
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          outlined
                          dense
                          hide-details
                          :label="$t('tests.machineTitle')"
                          :items="testMachines"
                          item-text="text"
                          item-value="value"
                          v-model="item.testMachineGuid"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          :label="$t('tests.machineCode')"
                          outlined
                          dense
                          hide-details
                          v-model="item.machineCode"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto" align-self="center" class="pa-1">
                    <v-btn
                      icon
                      color="red darken-2"
                      @click="deleteParameter(i)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              color="primary"
              class="white--text"
              @click="saveParameter"
              :disabled="!validParameter"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeParameters">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <Dialog
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  components: { DataTableComponent, ConfirmDialog, Dialog },
  data() {
    return {
      loading: true,
      valid: true,
      validParameter: true,
      dialog: false,
      dialogDelete: false,
      dialogParameters: false,
      editedIndex: -1,
      search: "",
      particularType: null,
      testBodies: [],
      testHeaders: [],
      testMachines: [],
      headerGuid: "",
      editedItem: { useTemplate: false, isActive: true },
      defaultItem: { useTemplate: false, isActive: true },
      resetValidation: 0,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },

  computed: {
    particularTypes() {
      var list = [];
      if (this.$store.getters.isInRole(106))
        list.push({
          text: this.$t("tests.particularTypes.Pathology"),
          value: 0,
        });
      if (this.$store.getters.isInRole(107))
        list.push({
          text: this.$t("tests.particularTypes.Radiology"),
          value: 1,
        });
      if (this.$store.getters.isInRole(108))
        list.push({
          text: this.$t("tests.particularTypes.Histopathology"),
          value: 2,
        });
      if (this.$store.getters.isInRole(109))
        list.push({
          text: this.$t("tests.particularTypes.Audiology"),
          value: 3,
        });

      return list;

      // return [
      //   { text: this.$t("tests.particularTypes.Pathology"), value: 0 },
      //   { text: this.$t("tests.particularTypes.Radiology"), value: 1 },
      //   { text: this.$t("tests.particularTypes.Histopathology"), value: 2 },
      //   { text: this.$t("tests.particularTypes.Audiology"), value: 3 },
      // ];
    },
    headers() {
      var list = [
        { text: this.$t("tests.seq"), value: "seq" },

        {
          text: this.$t("tests.testName"),
          value: "testHeaderSchemaGuid",
          type: "select",
          items: this.testHeaders,
        },
        { text: this.$t("tests.parameterCode"), value: "parameterCode" },
        { text: this.$t("tests.parameterName"), value: "parameterName" },
        { text: this.$t("tests.unit"), value: "unit" },
        { text: this.$t("tests.normalRange"), value: "normalRange" },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(86)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-link-box-variant-outline",
          text: this.$t("tests.linkParameters"),
          click: this.linkParameters,
        });
      }

      if (this.$store.getters.isInRole(86)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(87)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();
    if (this.particularTypes.length > 0) {
      this.particularType = this.particularTypes[0].value;
      this.getHeaders();
    }
    if (this.$store.getters.isInRole(88)) {
      this.$axios
        .get("TestBodySchema/GetMachines")
        .then((response) => {
          for (let index = 0; index < response.data.data.length; index++) {
            var element = response.data.data[index];
            this.testMachines.push({
              text: element.machineTitle,
              value: element.guid,
            });
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    }
  },

  watch: {
    dialog(val) {
      if (this.headerGuid) {
        this.editedItem.testHeaderSchemaGuid = this.headerGuid;
        val || this.close();
      } else {
        this.dialog = false;
        if (!val) {
          this.$toast.error(this.$t("tests.selectHeaderFirst"));
        }
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    particularType() {
      this.headerGuid = "";
      this.testBodies = [];
      this.getHeaders();
    },
    headerGuid() {
      this.refreshTable();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.testBodies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    linkParameters(item) {
      this.editedIndex = this.testBodies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogParameters = true;
    },

    deleteItem(item) {
      this.editedIndex = this.testBodies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.testBodies[this.editedIndex];
      this.$axios
        .delete("TestBodySchema/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },

    closeParameters() {
      this.dialogParameters = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    addParameter() {
      this.editedItem.testMachineInterfaces.push({});
    },
    deleteParameter(index) {
      this.editedItem.testMachineInterfaces.splice(index, 1);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      if (this.headerGuid) {
        this.$axios
          .get("TestBodySchema?headerGuid=" + this.headerGuid)
          .then((response) => {
            this.testBodies = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    getHeaders() {
      if (this.particularType >= 0) {
        this.$axios
          .get("TestHeaderSchema/GetByType?type=" + this.particularType)
          .then((response) => {
            for (let index = 0; index < response.data.data.length; index++) {
              var element = response.data.data[index];
              this.testHeaders.push({
                text: element.testName,
                value: element.guid,
              });
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          });
      }
      this.testHeaders = [];
    },

    saveParameter() {
      var val = this.$refs.formParameter.validate();
      if (val) {
        this.$axios
          .put(
            "TestBodySchema/Update?guid=" + this.editedItem.guid,
            this.editedItem
          )
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              this.closeParameters();
              this.refreshTable();
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            this.refreshTable();

            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
